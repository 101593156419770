import { z } from "zod";

export default z
  .object({
    origin: z.string(),
    t_name: z.string(),
    t_id: z.string(),
    v_id: z.string(),
    v_name: z.string(),
  })
  .strict();
