let enabled = true;

export const setGargantuanEnabledStatus = (newStatus: boolean): void => {
  if (typeof newStatus !== "boolean") {
    return;
  }
  enabled = newStatus;
};

export const getGargantuanEnabledStatus = (): boolean => enabled;
