import log from "utils/log";

import store from "../../store";

export default (name: string, payload: Record<string, unknown>): void => {
  if (
    (Array.isArray(payload) && payload.length === 0) ||
    typeof payload !== "object"
  ) {
    return;
  }
  try {
    store.dispatch({ name, payload });
  } catch (e) {
    log.warn(e);
  }
};
