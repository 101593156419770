import {
  GDPRConsent,
  GDPRDataResponse,
  PingReturn,
  TCFAPI,
  USPAPI,
  USPConsent,
  USPDataResponse,
} from "types/Cmp";
import { getEnv } from "utils/env";
import log from "utils/log";

import getTCFAPI from "./api/tcfapi";
import getUSPAPI from "./api/uspapi";

const getGDPRLoaded = async (): Promise<boolean> => {
  let pingInterval: number;

  return new Promise<boolean>((resolve) => {
    const env = getEnv();
    pingInterval = env.setInterval(() => {
      getTCFAPI()
        .then((tcfapi: TCFAPI) => {
          tcfapi("ping", 2, (response: PingReturn) => {
            if (response.cmpStatus === "loaded") {
              env.clearInterval(pingInterval);
              resolve(true);
            }
            if (response.cmpStatus === "loaded" && response.mocked) {
              env.clearInterval(pingInterval);
              resolve(true);
            }
          });
        })
        .catch((error: Error) => {
          log.error(`Error retrieving GDPR loaded. ${error.message}`);
          env.clearInterval(pingInterval);
          resolve(false);
        });
    }, 50);
  });
};

export const getGDPRConsent = async (): Promise<GDPRConsent> => {
  const gdprLoaded = await getGDPRLoaded();

  let gdprDataResponse: GDPRDataResponse | null = null;
  if (gdprLoaded) {
    gdprDataResponse = await new Promise<GDPRDataResponse | null>(
      (resolve, reject) => {
        getTCFAPI().then((tcfapi: TCFAPI) => {
          tcfapi(
            "addEventListener",
            2,
            (data: GDPRDataResponse, success: boolean) => {
              if (!success) {
                reject(
                  new Error(
                    `Failed to get GDPR consent data, __tcfapi addEventListener request failed`
                  )
                );
              }

              if (
                data.eventStatus === "tcloaded" ||
                data.eventStatus === "useractioncomplete"
              ) {
                resolve(data);
              }
            }
          );
        });
      }
    ).catch((error: Error) => {
      log.error(`Error retrieving GDPR consent. ${error.message}`);
      return null;
    });
  }

  if (gdprDataResponse === null) {
    return { askedForConsent: false, consent: null };
  }

  return { askedForConsent: true, consent: gdprDataResponse };
};

export const getUSPConsent = async (): Promise<USPConsent> => {
  const uspDataResponse = await new Promise<USPDataResponse | null>(
    (resolve, reject) => {
      getUSPAPI().then((uspapi: USPAPI) => {
        uspapi(
          "getUSPData",
          1,
          (response: USPDataResponse | null, success: boolean) => {
            if (
              response &&
              success &&
              // eslint-disable-next-line ban/ban
              Object.keys(response).includes("uspString")
            ) {
              resolve(response);
            } else {
              reject(
                new Error(
                  `Failed to get USP consent data, __uspapi getUSPData request failed`
                )
              );
            }
          }
        );
      });
    }
  ).catch((error: Error) => {
    log.error(`Error retrieving USP consent. ${error.message}`);
    return null;
  });

  if (uspDataResponse === null) {
    return { askedForConsent: false, consent: null, ccpaApplies: false };
  }

  if (uspDataResponse.uspString.includes("1---")) {
    return { askedForConsent: true, consent: null, ccpaApplies: false };
  }

  return {
    askedForConsent: true,
    consent: uspDataResponse,
    ccpaApplies: true,
  };
};

export const hasUserConsentedVendorGDPR = async (
  iabID?: string | number
): Promise<boolean> => {
  const response = await getGDPRConsent();
  if (response.consent === null) {
    return false;
  }

  const { gdprApplies, vendor } = response.consent;
  const vendorConsented =
    iabID !== undefined &&
    vendor?.consents &&
    iabID in vendor.consents &&
    vendor.consents[iabID as keyof typeof vendor.consents];

  return !gdprApplies || vendorConsented;
};

export const hasUserOptOutCCPA = async (): Promise<boolean> => {
  const uspConsent = await getUSPConsent();

  if (
    !uspConsent ||
    !uspConsent.ccpaApplies ||
    !uspConsent.consent ||
    uspConsent.consent.uspString.length < 3
  ) {
    return false;
  }

  return uspConsent.consent.uspString[2] === "Y";
};

export default {};
