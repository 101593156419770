import { USPAPI } from "types/Cmp";
import cookies from "utils/cookies";
import { getEnv } from "utils/env";
import log from "utils/log";

import { CMP_TIMEOUT, findAPI } from "./utils";

// Set default usprivacy cookie if it does not exist
// https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md
if (!cookies.get("usprivacy")) {
  const env = getEnv();
  cookies.set(
    "usprivacy",
    "1YNN",
    60 * 60 * 24 * 365,
    env.window?.top?.location.host
  );
}

export const mockUSPAPI = (): USPAPI => {
  return (command, version, callback): void => {
    if (command !== "getUSPData") {
      return;
    }
    callback({ version, uspString: "1---" }, true);
  };
};

export default async (): Promise<USPAPI> => {
  const uspapiPromise = new Promise<USPAPI>((resolve, reject) => {
    const uspapi = findAPI("__uspapi");

    if (uspapi === null) {
      reject(new Error("__uspapi does not exist"));
      return;
    }

    resolve(uspapi);
  });

  const timeoutPromise = new Promise<USPAPI>((_resolve, reject) => {
    const env = getEnv();
    env.setTimeout(
      () => reject(new Error(`__uspapi timeout after ${CMP_TIMEOUT}ms`)),
      CMP_TIMEOUT
    );
  });

  return Promise.race([uspapiPromise, timeoutPromise]).catch((reason) => {
    log.error(reason);
    return mockUSPAPI();
  });
};
