import { z } from "zod";

const bid = z.object({
  ad_id: z.string().nullish(),
  auction_id: z.string().nullish(),
  adunit: z.string().nullish(),
  bidder: z.string().nullish(),
  cpm: z.number().nullish(),
  ad_size: z.string().nullish(),
  response_time: z.number().nullish(),
  deal_id: z.string().nullish(),
  creative_id: z.string().nullish(),
  media_type: z.string().nullish(),
});

export default z
  .object({
    id: z.number(),
    partner: z.string(),
    bids: z.array(bid),
  })
  .deepPartial();
