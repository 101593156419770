import { GenericEvent } from "types/Events";

type Handler<T> = (event: T) => unknown;
interface Stream<T> {
  dispatch(event: T): void;
  getState(): T[];
  subscribe: (handler: Handler<T>) => () => void;
}

export const createStream = <T>(initialState: T[]): Stream<T> => {
  const state: T[] = initialState;
  const subscribers: Array<Handler<T>> = [];
  return {
    dispatch: (event: T): void => {
      state.push(event);
      subscribers.forEach((handler) => handler(event));
    },
    getState: () => state,
    subscribe: (handler: Handler<T>) => {
      subscribers.push(handler);
      return (): void => {
        const index = subscribers.indexOf(handler);
        if (index > -1) {
          subscribers.splice(index, 1);
        }
      };
    },
  };
};

export default createStream([] as GenericEvent[]);
