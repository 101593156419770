import { HybridAbTestEvent, PageLoadEvent } from "types/Events";

const pageEvent = ({ payload: data }: PageLoadEvent) => ({
  ...data,
  page: {
    ...(data.page || {}),
    tags: Array.isArray(data.page?.tags) ? data.page?.tags?.join("|") : "",
  },
  content: {
    ...data.content,
    article: {
      ...data.content?.article,
      authors: Array.isArray(data.content?.article.authors)
        ? data.content?.article.authors.join("|")
        : "",
      verticals: Array.isArray(data.content?.article.verticals)
        ? data.content?.article.verticals.join("|")
        : "",
      subVerticals: Array.isArray(data.content?.article.subVerticals)
        ? data.content?.article.subVerticals.join("|")
        : "",
    },
  },
  product: {
    ...data.product,
    secondary: {
      brands: Array.isArray(data.product?.secondary?.brands)
        ? data.product?.secondary?.brands.join("|")
        : "",
      products: Array.isArray(data.product?.secondary?.products)
        ? data.product?.secondary?.products.join("|")
        : "",
    },
  },
});

const hybridAbTestEvent = ({ payload: data }: HybridAbTestEvent) => {
  const { origin, t_name, t_id, v_id, v_name } = data ?? {};
  return { experiment: { origin, t_name, t_id, v_id, v_name } };
};

export default { pageEvent, hybridAbTestEvent };
