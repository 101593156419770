import { z } from "zod";

import auctionPartnerSchema from "./AuctionPartnerEvent";
import ecommerceSchema from "./Ecommerce";
import hawkSchema from "./HawkEvent";
import hybridAbTestSchema from "./HybridAbTest";
import pageLoadSchema from "./PageLoadEvent";
import userSchema from "./User";

const eventSchemaGenerator = <T extends z.ZodTypeAny>(
  schema: T,
  name: string
) => {
  return z
    .object({ payload: schema })
    .merge(z.object({ name: z.literal(name) }));
};

// Zod Schemas
export const hawkEventSchema = eventSchemaGenerator(hawkSchema, "hawkEvent");
export const pageLoadEventSchema = eventSchemaGenerator(
  pageLoadSchema,
  "pageLoadEvent"
);
export const auctionPartnerEventSchema = eventSchemaGenerator(
  auctionPartnerSchema,
  "auctionPartnerEvent"
);
export const userEventSchema = eventSchemaGenerator(userSchema, "userIdEvent");
export const hybridAbTestEventSchema = eventSchemaGenerator(
  hybridAbTestSchema,
  "hybridAbTestEvent"
);
export const ecommerceEventSchema = eventSchemaGenerator(
  ecommerceSchema,
  "ecommerceEvent"
);

// TypeScript Types
export type EcommerceEvent = z.infer<typeof ecommerceEventSchema>;
export type HawkEvent = z.infer<typeof hawkEventSchema>;
export type HybridAbTestEvent = z.infer<typeof hybridAbTestEventSchema>;
export type PageLoadEvent = z.infer<typeof pageLoadEventSchema>;
export type AuctionPartnerEvent = z.infer<typeof auctionPartnerEventSchema>;
export type UserEvent = z.infer<typeof userEventSchema>;

export type EcommerceData = z.infer<typeof ecommerceSchema>;
export type HawkData = z.infer<typeof hawkSchema>;
export type HybridAbTestData = z.infer<typeof hybridAbTestSchema>;
export type PageLoadData = z.infer<typeof pageLoadSchema>;
export type UserData = z.infer<typeof userSchema>;

export interface GenericEvent {
  name: string;
  payload: Record<string, unknown>;
}

export const isAuctionPartnerEvent = (
  event: GenericEvent
): event is AuctionPartnerEvent => event.name === "auctionPartnerEvent";

export const isPageLoadEvent = (event: GenericEvent): event is PageLoadEvent =>
  event.name === "pageLoadEvent";

export const isHawkEvent = (event: GenericEvent): event is HawkEvent =>
  event.name === "hawkEvent";

export const isUserIdEvent = (event: GenericEvent): event is UserEvent =>
  event.name === "userIdEvent";

export const isHybridAbTestEvent = (
  event: GenericEvent
): event is HybridAbTestEvent => event.name === "hybridAbTestEvent";
