import renameKey from "utils/rename-key";
import { z } from "zod";

export default z
  .object({
    affiliate: z.object({
      action: z
        .object({
          type: z.string(),
          id: z
            .number()
            .or(z.string())
            .transform((v) => parseInt(v.toString(), 10)),
          event: z.string(),
        })
        .deepPartial()
        .strip()
        .transform((o) => renameKey(o, "id", "elementId")),
      component: z
        .object({
          flag: z.string().nullish(),
          product: z.string().nullish(),
          category: z.string().nullish(),
          type: z.string().nullish(),
          label: z.string().nullish(),
          xrayUuid: z.number().nullish(),
        })
        .strip()
        .deepPartial(),
    }),
    products: z
      .object({
        product: z.object({
          primary: z
            .object({
              id: z.number().nullish(),
              name: z.string().nullish(),
              type: z.string().nullish(),
              price: z
                .number()
                .transform((val) => (!val ? val : parseInt(val.toString(), 10)))
                .nullish(),
              currency: z.string().nullish(),
              labels: z
                .object({
                  type: z.string(),
                  value: z.string(),
                })
                .strip()
                .array()
                .nullish(),
              linkDestination: z.string().nullish(),
              serviceProvider: z.string().nullish(),
            })
            .deepPartial()
            .strip()
            .transform((o) => renameKey(o, "revenueId", "revId")),
          source: z.string(),
        }),
        merchant: z
          .object({
            id: z.number().nullish(),
            name: z.string().nullish(),
            url: z.string().nullish(),
            network: z.string().nullish(),
          })
          .strip(),
        model: z
          .object({
            id: z.number().nullish(),
            brand: z.string().nullish(),
            name: z.string().nullish(),
            parent: z.string().nullish(),
          })
          .strip(),
      })
      .deepPartial()
      .strip()
      .array(),
  })
  .deepPartial()
  .strip();
