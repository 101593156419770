import { z } from "zod";

export default z
  .object({
    action: z
      .union([
        z.literal("view_item_list"),
        z.literal("view_item"),
        z.literal("select_item"),
        z.literal("add_to_cart"),
        z.literal("remove_from_cart"),
        z.literal("view_cart"),
        z.literal("begin_checkout"),
        z.literal("add_shipping_info"),
        z.literal("add_payment_info"),
        z.literal("purchase"),
        z.literal("refund"),
      ])
      .nullish(),
    transactionId: z.string().nullish(),
    value: z.number().nullish(),
    tax: z.number().nullish(),
    shipping: z.number().nullish(),
    currency: z.string().nullish(),
    paymentType: z.string().nullish(),
    coupon: z.string().nullish(),
    pdpTemplate: z.string().nullish(),
    pdpBrand: z.string().nullish(),
    pdpPrintItems: z.string().nullish(),
    pdpDigitalItems: z.string().nullish(),
    atbContents: z.string().nullish(),
    atbVariant: z.string().nullish(),
    csIntegration: z.string().nullish(),
    items: z.array(
      z
        .object({
          basketFriendlyReference: z.string().nullish(),
          itemListId: z.string().nullish(),
          itemName: z.string().nullish(),
          itemFormat: z.string().nullish(),
          itemType: z.string().nullish(),
          itemBrand: z.string().nullish(),
          itemCategory: z.string().nullish(),
          quantity: z.number().int().nullish(),
          price: z.number().nullish(),
          discount: z.number().nullish(),
          offerCode: z.string().nullish(),
          subscriptionTermType: z.string().nullish(),
          subscriptionTermLength: z.number().int().nullish(),
          giftSubscription: z
            .union([z.literal("GIFT"), z.literal("SELF")])
            .nullish(),
          deliveryTerritory: z.string().nullish(),
          deliveryCountry: z.string().nullish(),
        })

        .strict()
    ),
  })
  .strict();
