import { EventQueueItem } from "types/EventQueue";
import {
  GenericEvent,
  isHybridAbTestEvent,
  isPageLoadEvent,
} from "types/Events";
import { getEnv } from "utils/env";

import gaDataParser from "../ga/data-parser";
import permutiveEventDecoder from "../permutive/event-decoder";
import { yieldToController } from "./yield-to";

const allowedEvents = [
  "beforeUnload",
  "boostRequest",
  "boostResponse",
  "ecommerceEvent",
  "elementInteraction",
  "experimentEvent",
  "cmpEvent",
  "cmpModalInfo",
  "hawkEvent",
  "hybridAbTestEvent",
  "kiosqStuckOnSpinnerEvent",
  "newsletterEvent",
  "pageLoadEvent",
  "paywallEvent",
  "userEvent",
  "userIdEvent",
  "videoEvent",
];

const env = getEnv();
env.dataLayer = env.dataLayer || [];

const eventQueue: EventQueueItem[] = [];

const handleVisibilityChange = () => {
  if (document.visibilityState === "hidden") {
    flushEvents();
  }
};

let visibilityListenerAttached = false;

export const attachVisibilityChangeListener = () => {
  if (!visibilityListenerAttached) {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    visibilityListenerAttached = true;
  }
};

const parseGAEvent = (
  data: GenericEvent
): { event: string; [key: string]: any } => {
  if (isPageLoadEvent(data)) {
    return { event: data.name, ...gaDataParser.pageEvent(data) };
  } else if (isHybridAbTestEvent(data)) {
    return { event: data.name, ...gaDataParser.hybridAbTestEvent(data) };
  } else {
    return { event: data.name, ...data.payload, _clear: true };
  }
};

const flushEvents = async () => {
  if (eventQueue.length > 0) {
    const eventsToProcess = [...eventQueue];
    eventQueue.length = 0;

    await Promise.all(
      eventsToProcess.map(async (event) => {
        return yieldToController(
          () => {
            event.type === "GA"
              ? env.dataLayer?.push(event.data)
              : permutiveEventDecoder(event.data).catch(() => {});
          },
          { priority: "background" }
        );
      })
    );
  }
};

export const addEventToQueue = (
  data: GenericEvent,
  endpoint: "GA" | "Permutive"
): void => {
  if (!allowedEvents.includes(data.name)) {
    return;
  }

  const testSites = ["imore", "livescience", "creativebloq"];

  // tests for imore and livescience
  if (
    (env.ffte && !testSites.includes(env.ffte.site)) ||
    isPageLoadEvent(data)
  ) {
    if (endpoint === "GA") {
      env.dataLayer?.push(parseGAEvent(data));
      return;
    }
    if (endpoint === "Permutive") {
      permutiveEventDecoder(data);
      return;
    }
  }

  if (endpoint === "GA") {
    eventQueue.push({
      type: "GA",
      data: parseGAEvent(data),
    });
  } else if (endpoint === "Permutive") {
    eventQueue.push({
      type: "Permutive",
      data,
    });
  }

  yieldToController(() => flushEvents(), { priority: "background" });
};
