import { GenericEvent } from "types/Events";
import * as cmp from "utils/cmp";
import { getEnv } from "utils/env";
import { addEventToQueue } from "utils/event-batcher";
import log from "utils/log";

const env = getEnv();

export default async (encodeEvent: GenericEvent): Promise<void> => {
  const hasPermutiveGDPRConsent = await cmp.hasUserConsentedVendorGDPR(361);
  const userHasOptOutCCPA = await cmp.hasUserOptOutCCPA();

  if (!hasPermutiveGDPRConsent || userHasOptOutCCPA) {
    return Promise.reject(new Error("Unable to obtain consent"));
  }

  if (!env.permutive) {
    env.permutiveQueue = env.permutiveQueue || [];
    env.permutiveQueue.push(encodeEvent);

    document.addEventListener(
      "permutiveLoaded",
      () => {
        if (!env.permutiveQueue?.length) {
          return;
        }

        env.permutiveQueue?.forEach((event) =>
          addEventToQueue(event, "Permutive")
        );
      },
      { once: true }
    );

    log.warn("Permutive is not loaded yet, queuing events");
    return Promise.resolve();
  }

  addEventToQueue(encodeEvent, "Permutive");

  return Promise.resolve();
};
