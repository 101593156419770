import { z } from "zod";

const pageDecoder = z.object({
  title: z.string().nullable(),
  url: z.string().nullable(),
  type: z.string().nullable(),
  category: z.string().nullable(),
  region: z.string().nullable(),
  tags: z.array(z.string()).nullable(),
  number: z.string().nullable(),
});

const productDecoder = z.object({
  primary: z.object({
    name: z.string().nullable(),
    brand: z.string().nullable(),
    category: z.string().nullable(),
  }),

  secondary: z.object({
    products: z.array(z.string()).nullable(),
    brands: z.array(z.string()).nullable(),
  }),

  source: z.string().nullable(),
});

const articleDecoder = z.object({
  verticals: z.array(z.string()).nullable(),
  subVerticals: z.array(z.string()).nullable(),
  language: z.string().nullable(),
  authors: z.array(z.string()).nullable(),
  id: z.string().nullable(),
  publishDate: z.object({
    year: z.string().nullable(),
    month: z.string().nullable(),
    day: z.string().nullable(),
  }),

  updateDate: z.object({
    year: z.string().nullable(),
    month: z.string().nullable(),
    day: z.string().nullable(),
  }),

  cmsCategory: z.string().nullable().optional(),
  cmsChildCategory: z.string().nullable().optional(),
  cmsGrandChildCategory: z.string().nullable().optional(),
});

const contentDecoder = z
  .object({
    article: articleDecoder,
    classification: z
      .object({
        google: z
          .array(z.object({ name: z.string(), confidence: z.number() }))
          .nullable(),
      })
      .default({ google: null }),
  })
  .transform((data) => ({ article: data.article ?? {} }));

const siteDecoder = z.object({
  region: z.string().nullable(),
  brand: z.string().nullable(),
});

const segmentDecoder = z.object({
  advisor: z.object({
    segmentArticleDistance: z
      .array(z.string().transform((val) => parseFloat(val)))
      .nullable(),
    segmentID: z.array(z.string()).nullable(),
    segmentMaxDistance: z
      .array(z.string().transform((val) => parseFloat(val)))
      .nullable(),
  }),
});

export default z
  .object({
    page: pageDecoder,
    content: contentDecoder,
    site: siteDecoder,
    product: productDecoder,
    segment: segmentDecoder,
  })
  .deepPartial();
