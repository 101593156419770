import {
  GDPRDataResponse,
  PingReturn,
  TCFAPI,
  TCFAPIEventTypeMap,
} from "types/Cmp";
import { getEnv } from "utils/env";
import log from "utils/log";

import { CMP_TIMEOUT, findAPI } from "./utils";

export const mockTCFAPI = (): TCFAPI => {
  return (command, version, callback): void => {
    const noConsent: GDPRDataResponse = {
      cmpId: 10,
      cmpVersion: 23,
      gdprApplies: true,
      tcfPolicyVersion: version,
      eventStatus: "tcloaded",
      cmpStatus: "loaded",
      tcString: "",
      isServiceSpecific: true,
      useNonStandardStacks: false,
      purposeOneTreatment: false,
      publisherCC: "GB",
      outOfBand: { allowedVendors: {}, disclosedVendors: {} },
      purpose: { consents: {}, legitimateInterests: {} },
      vendor: { consents: {}, legitimateInterests: {} },
      specialFeatureOptins: {},
      publisher: {
        consents: {},
        legitimateInterests: {},
        customPurpose: { consents: {}, legitimateInterests: {} },
        restrictions: {},
      },
    };

    const ping: PingReturn = {
      cmpId: 10,
      cmpVersion: 23,
      apiVersion: "23",
      gdprApplies: true,
      tcfPolicyVersion: version,
      cmpLoaded: true,
      cmpStatus: "loaded",
      displayStatus: "hidden",
    };

    switch (command) {
      case "getTCData":
        (callback as TCFAPIEventTypeMap["getTCData"])(noConsent, true);
        break;
      case "ping":
        (callback as TCFAPIEventTypeMap["ping"])(ping);
        break;
      case "addEventListener":
        (callback as TCFAPIEventTypeMap["addEventListener"])(noConsent, true);
        break;
      case "removeEventListener":
        (callback as TCFAPIEventTypeMap["removeEventListener"])(true);
        break;
      case "displayConsentUi":
        (callback as TCFAPIEventTypeMap["displayConsentUi"])();
        break;
      default:
        log.error(`Unknown command for mocked __tcfapi: ${command}`);
    }
  };
};

export default async (): Promise<TCFAPI> => {
  const tcfapiPromise = new Promise<TCFAPI>((resolve, reject) => {
    const tcfapi = findAPI("__tcfapi");

    if (tcfapi === null) {
      reject(new Error("__tcfapi does not exist"));
      return;
    }

    resolve(tcfapi);
  });

  const timeoutPromise = new Promise<TCFAPI>((_resolve, reject) => {
    const env = getEnv();
    env.setTimeout(
      () => reject(new Error(`__tcfapi timeout after ${CMP_TIMEOUT}ms`)),
      CMP_TIMEOUT
    );
  });

  return Promise.race([tcfapiPromise, timeoutPromise]).catch((reason) => {
    log.error(reason);
    return mockTCFAPI();
  });
};
