import log from "utils/log";

export default (
  commands: Array<() => void>,
  initialiseCallback: (startQueue: () => void) => void
): void => {
  if (!Array.isArray(commands)) {
    log.error("Command queue commands is not an array");
    return;
  }

  if (typeof initialiseCallback !== "function") {
    log.error(`Command queue initialiseCallback is not a function.`);
    return;
  }

  const pushToQueue = (command: () => void): number => {
    if (typeof command !== "function") {
      log.error(
        `Command queue will only accept a function callback command, '${typeof command}' given.`
      );
      return 0;
    }

    command();
    return 0;
  };

  const initialise = (): void => {
    commands.forEach((command) => {
      command();
    });
    // eslint-disable-next-line no-param-reassign
    commands.push = pushToQueue;
  };
  initialiseCallback(initialise);
};
