import { getEnv } from "utils/env";

const get = (name: string): string | undefined => {
  const env = getEnv();
  const value = `; ${env.document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const end = parts.pop();
    if (end) {
      return end.split(";").shift();
    }
  }

  return undefined;
};

const set = (
  name: string,
  value: string,
  lifespanInSec: number,
  domain?: string
): void => {
  const env = getEnv();
  const d = new Date();
  d.setTime(d.getTime() + lifespanInSec * 1000);
  const cookieExpires = `expires=${d.toUTCString()}`;
  const cookieDomain = domain ? `domain=${domain};` : "";
  env.document.cookie = `${name}=${value};${cookieExpires};path=/;${cookieDomain}SameSite=Lax;secure`;
};

export default {
  get,
  set,
};
