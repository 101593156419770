import {
  GenericEvent,
  hawkEventSchema,
  isHawkEvent,
  isPageLoadEvent,
  isUserIdEvent,
  pageLoadEventSchema,
  userEventSchema,
} from "types/Events";
import { getEnv } from "utils/env";

import parser from "./data-parser";

const env = getEnv();

export default async (encodeEvent: GenericEvent) => {
  if (isPageLoadEvent(encodeEvent)) {
    const decodedEvent = pageLoadEventSchema.safeParse(encodeEvent);
    if (decodedEvent.success === false) {
      return Promise.reject(new Error("Unable to parse event"));
    }

    env.permutive?.consent({ opt_in: true, token: "CONSENT_CAPTURED" });
    env.permutive?.addon("web", parser.pageEvent(decodedEvent.data));
    return Promise.resolve();
  }

  if (isHawkEvent(encodeEvent)) {
    try {
      const decodedEvent = await hawkEventSchema.parseAsync(encodeEvent);

      if (
        decodedEvent.payload.affiliate?.action?.event === "Click from" ||
        decodedEvent.payload.affiliate?.action?.event === "viewed"
      ) {
        env.permutive?.track("Affiliates", decodedEvent.payload);
        return Promise.resolve();
      }

      if (
        decodedEvent.payload.affiliate?.action?.event === "Click from" ||
        decodedEvent.payload.affiliate?.action?.event === "viewed"
      ) {
        env.permutive?.track("Affiliates", decodedEvent.payload);
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  if (isUserIdEvent(encodeEvent)) {
    const decodedEvent = userEventSchema.safeParse(encodeEvent);

    if (decodedEvent.success === false) {
      return Promise.reject(new Error("Unable to parse event"));
    }

    env.permutive!.identify([parser.userEvent(decodedEvent.data.payload)]);
    return Promise.resolve();
  }
};
