import { getEnv } from "./env";

type SchedulerOptions = {
  priority?: "user-blocking" | "background" | "user-visible";
};

const env = getEnv() ?? {};

const yieldToMain = (options?: SchedulerOptions): Promise<void> => {
  if (env.scheduler) {
    if (env.scheduler.yield) {
      return env.scheduler.yield(options);
    }
    if (env.scheduler.postTask) {
      return env.scheduler.postTask(() => {}, options);
    }
  }

  if (options?.priority === "background") {
    return Promise.resolve();
  }

  return new Promise((resolve) => setTimeout(resolve));
};

export const yieldToController = async <T>(
  fn: () => T | Promise<T>,
  options?: SchedulerOptions
): Promise<T> => {
  await yieldToMain(options);
  return fn();
};
