import { __tcfapi, __uspapi, TCFAPI, USPAPI } from "types/Cmp";
import { Env } from "types/Env";
import { getEnv } from "utils/env";

export const CMP_TIMEOUT = 25000;

// Search the window's ancestors for an attached variable
export function findAPI<APIName extends __tcfapi | __uspapi>(
  api: APIName
): (APIName extends __tcfapi ? TCFAPI : USPAPI) | null {
  const env = getEnv();
  const { top } = env;
  let frame: Window | Env = env;

  do {
    try {
      if (frame && api in frame) {
        break;
      }
    } catch (e) {}
    frame = frame?.parent ?? null;
  } while (frame && frame !== top);

  if (frame && api in frame) {
    return (frame as Record<string, any>)[api];
  }

  return null;
}
