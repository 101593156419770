import { AuctionPartnerEvent, GenericEvent, isAuctionPartnerEvent, isPageLoadEvent, PageLoadEvent } from "types/Events";
import log from "utils/log";

const eventMapping: { [key: string]: string} = {
  "pageLoadEvent": "https://eventsproxy.gargantuan.futureplc.com/future.adtech.freyr.v1.PageViewEvent",
  "auctionPartnerEvent": "https://eventsproxy.gargantuan.futureplc.com/future.adtech.bordeaux.v1.AuctionPartnerBidRequestCompletedEvent",
}

const sendToGargantuan = (data: PageLoadEvent | AuctionPartnerEvent): Promise<Response> => {
  return fetch(
    eventMapping[data.name],
    {
      method: "POST",
      body: JSON.stringify(data.payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const allowedEvent = (event: GenericEvent): event is PageLoadEvent | AuctionPartnerEvent =>
  isPageLoadEvent(event) || isAuctionPartnerEvent(event);

export default (data: GenericEvent): void => {
  if (allowedEvent(data)) {
    sendToGargantuan(data).catch((err) => {
      log.warn(err.message);
    });
  }
};
