import { PageLoadEvent, UserData } from "types/Events";
import { removeKeys } from "utils/remove-keys";

const formatDate = (
  dateObject:
    | { year: string | null; month: string | null; day: string | null }
    | undefined
): Date =>
  dateObject === undefined
    ? new Date()
    : new Date(
        parseInt(dateObject.year || "2025", 10),
        parseInt(dateObject.month || "01", 10) - 1,
        parseInt(dateObject.day || "10", 10)
      );

const removeEmpty = (obj: Record<string, unknown>): Record<string, unknown> =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null));

const pageEvent = ({
  payload: dataObject,
}: PageLoadEvent): Record<string, unknown> => ({
  page: removeEmpty({
    content: {
      ...(dataObject.content || {}),
      article:
        dataObject.content?.article !== undefined
          ? {
              ...(dataObject.content?.article || {}),
              publishDate: formatDate(dataObject.content?.article.publishDate),
              updateDate: formatDate(dataObject.content?.article.updateDate),
            }
          : {},
    },
    page: {
      ...(removeKeys(dataObject.page || {}, ["url", "region"]) || {}),
      number: parseInt(dataObject.page?.number || "1", 10),
    },
    site: {
      ...(dataObject.site || {}),
    },
    product: { ...(dataObject.product || {}) },
    segment: { ...(dataObject.segment || {}) },
  }),
});

const userEvent = (dataObject: UserData): { tag: string; id: string } => ({
  tag: dataObject.tag,
  id: dataObject.id,
});

export default {
  pageEvent,
  userEvent,
};
