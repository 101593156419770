import cmpStatus from "api/cmpStatus";
import commandQueue from "api/command-queue";
import { getGargantuanEnabledStatus } from "api/disabledGargantuan";
import { getGaEnabledStatus, setGaEnabledStatus } from "api/disableGa";
import {
  getPermutiveEnabledStatus,
  setPermutiveEnabledStatus,
} from "api/disablePermutive";
import getAllValues from "api/getAllValues";
import pushAndUpdate from "api/pushAndUpdate";
import pushToGa from "ga";
import pushToGargantuan from "gargantuan";
import pushToPermutive from "permutive";
import { getEnv } from "utils/env";
import { attachVisibilityChangeListener } from "utils/event-batcher";
import log from "utils/log";

import store from "./store";

const env = getEnv();

attachVisibilityChangeListener();

env.freyr = {
  cmd: env.freyr?.cmd || [],
  pushAndUpdate,
  getAllValues,
  setGaEnabledStatus,
  setPermutiveEnabledStatus,
  cmpStatus,
};

store.subscribe((event) => {
  if (getGaEnabledStatus()) {
    pushToGa(event);
  }
  if (getPermutiveEnabledStatus()) {
    pushToPermutive(event).catch((e) => log.warn(e));
  }
  if (getGargantuanEnabledStatus()) {
    pushToGargantuan(event);
  }
});

commandQueue(env.freyr?.cmd || [], (startQueue) => startQueue());
