import { getEnv } from "utils/env";

const env = getEnv();

export default new Promise<boolean>((resolve): void => {
  if (env.__tcfapi && env.__uspapi) {
    resolve(true);
    return;
  }

  if (env.tmntag && env.tmntag.cmd) {
    env.tmntag.cmd.push(() => {
      resolve(true);
    });
    return;
  }

  resolve(true);
});
