import { GenericEvent } from "types/Events";
import * as cmp from "utils/cmp";
import { getEnv } from "utils/env";
import { addEventToQueue } from "utils/event-batcher";

const env = getEnv();

export default async (encodeEvent: GenericEvent): Promise<void> => {
  const hasPermutiveGDPRConsent = await cmp.hasUserConsentedVendorGDPR(361);
  const userHasOptOutCCPA = await cmp.hasUserOptOutCCPA();

  if (!env.permutive) {
    return Promise.reject(new Error("Permutive object is missing on Window"));
  }

  if (!hasPermutiveGDPRConsent || userHasOptOutCCPA) {
    return Promise.reject(new Error("Unable to obtain consent"));
  }

  addEventToQueue(encodeEvent, "Permutive");

  return Promise.resolve();
};
